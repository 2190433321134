const githubLink = "https://github.com/llleeeaaannn";

const linkedinLink = "https://www.linkedin.com/in/frank-pierce-88a269263/";

const mediumLink = "https://piercefrank10.medium.com/";

const devtoLink = "https://dev.to/llleeeaaannn";

const emailLink = "mailto:frank.pierceee@gmail.com";

const theHrEmailLink = "mailto:frank.pierce@thehrcompany.ie";

export { githubLink, linkedinLink, mediumLink, devtoLink, emailLink, theHrEmailLink }
